<div class="container my-3">
    <div class="row">
        <div class="col-6">
            <img src="../../../assets/images/logo.png" width="120" />
        </div>
        <div class="col-6 text-end">
            <h6 *ngIf="featureReqData.remainingFeaturePoints">YOU HAVE {{featureReqData.remainingFeaturePoints}} VOTES</h6>
            <h6 *ngIf="!featureReqData.remainingFeaturePoints">YOU HAVE NO REMAINING VOTES</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <h3>Feature Requests For AvSight</h3>
            <p>When you submit a support ticket or a change request to the AvSight product team it may be converted into a feature request. Feature requests are then added to the Feature Repository where AvSight users can cast a vote to guide the direction of future AvSight releases.</p>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-3 background">
            <div class="row p-3 filter-container">
                <div class="col-12">
                    <label class="fs-6">Filter By:</label>
                </div>
                <div class="col-12 mt-2">
                    <label>CATEGORY</label>
                    <select class="form-select" [(ngModel)]="filterParam.category" (change)="onFilterChange()">
                        <option selected value="">All Categories</option>
                        <option *ngFor="let cat of featureReqData.featurePointsCategoryList" value="{{cat.key}}">{{cat.label}}</option>
                    </select>
                </div>
                <div class="col-12 mt-2">
                    <label>STATUS</label>
                    <select class="form-select" [(ngModel)]="filterParam.seletedStatus" (change)="onFilterChange()">
                        <option selected value="">Any Status</option>
                        <option *ngFor="let st of featureReqData.featurePointsStatusList" value="{{st}}">{{st}}</option>
                    </select>
                </div>
                <div class="col-12 mt-2">
                    <label>SORT</label>
                    <select class="form-select" [(ngModel)]="filterParam.sortType" (change)="onFilterChange()">
                        <option value="Popular" selected>Max Points</option>
                        <option value="Recent">Most Recent</option>
                    </select>
                </div>
                <div class="col-12 mt-2">
                    <label>KEYWORD</label>
                    <span>
                        <input type="text" class="form-control" placeholder="" [(ngModel)]="filterParam.searchText" (change)="onFilterChange()">
                    </span>
                </div>
                <div class="col-12 mt-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="showMyReq" [(ngModel)]="filterParam.showMyReq" (change)="onFilterChange()">
                        <label class="form-check-label" for="showMyReq">
                            Only My Requests
                        </label>
                    </div>
                </div>
                <div class="col-12 mt-3 text-end">
                    <button *ngIf="filterParam.applied" class="btn btn-primary me-2" type="button" (click)="clearFilter()">Clear</button>
                    <button class="btn btn-primary" type="button" (click)="onApplyFilter()">Apply</button>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="row px-2 mb-2" *ngIf="pagination.totalPage > 1">
                <div class="col-12 background py-2 text-end">
                    <button class="btn btn-primary shrink" (click)="changePage(this.pagination.pageNo - 1)" [disabled]="pagination.pageNo == 1">Previous</button>
                    {{pagination.pageNo}}&nbsp;/&nbsp;{{pagination.totalPage}}
                    <button class="btn btn-primary shrink" (click)="changePage(this.pagination.pageNo + 1)" [disabled]="pagination.pageNo >= pagination.totalPage">Next</button>
                </div>
            </div>
            <div *ngFor="let fItem of featureRequestList; let i = index" [attr.data-index]="i" class="feature-item mb-2 card" data-type='view'>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="idea-title">{{fItem.Name}}</div>
                            <div class="idea-subtitle mt-1 mb-3">
                                {{fItem.Category__c || 'NA'}}
                                <span></span>
                                <label *ngIf="fItem.Status__c == 'New' || fItem.Status__c == 'Verified' || fItem.Status__c == 'On Roadmap'">Ideas</label>
                                <label *ngIf="fItem.Status__c == 'In Development'">Chosen</label>
                                <label *ngIf="fItem.Status__c == 'Delivered' || fItem.Status__c == 'Merged' || fItem.Status__c == 'Existing Feature'">Delivered</label>
                                <label *ngIf="fItem.Status__c == 'Not for AvS Core'">Rejected</label>
                            </div>
                            <div class="idea-desc">{{fItem.Feature_Description__c}}</div>
                        </div>
                        <div class="col-md-2">
                            <div class="vote-container">
                                <span>{{fItem.Total_Points__c}} POINT{{fItem.Total_Points__c > 1 ? 'S' : ''}}</span>
                                <div *ngIf="!fItem.isVoted && featureReqData.remainingFeaturePoints > 0 && (fItem.Status__c == 'New' || fItem.Status__c == 'Verified' || fItem.Status__c == 'On Roadmap')" class='upvote-btn' data-type='vote' (click)="voteFeatureReq(fItem.Id, false)">UPVOTE</div>
                                <div *ngIf="fItem.isVoted && (fItem.Status__c == 'New' || fItem.Status__c == 'Verified' || fItem.Status__c == 'On Roadmap')" class='upvote-btn' data-type='vote' (click)="voteFeatureReq(fItem.Id, true)">REMOVE VOTE</div>
                                <div *ngIf="fItem.Status__c != 'New' && fItem.Status__c != 'Verified' && fItem.Status__c != 'On Roadmap'" class='upvote-btn status'>
                                    <span *ngIf="fItem.Status__c == 'In Development'">Chosen</span>
                                    <span *ngIf="fItem.Status__c == 'Delivered' || fItem.Status__c == 'Merged' || fItem.Status__c == 'Existing Feature'">Delivered</span>
                                    <span *ngIf="fItem.Status__c == 'Not for AvS Core'">Rejected</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-2 m2-2" *ngIf="pagination.totalPage > 1">
                <div class="col-12 background py-2 text-end">
                    <button class="btn btn-primary shrink" (click)="changePage(this.pagination.pageNo - 1)" [disabled]="pagination.pageNo == 1">Previous</button>
                    {{pagination.pageNo}}&nbsp;/&nbsp;{{pagination.totalPage}}
                    <button class="btn btn-primary shrink" (click)="changePage(this.pagination.pageNo + 1)" [disabled]="pagination.pageNo >= pagination.totalPage">Next</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- feature request info -->
<div class="modal fade" id="featureReqDataModal" role="dialog" aria-labelledby="featureReqDataModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="featureReqDataModalLabel">{{featureReq.Name}}</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideFeatureReqDialog()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="form-group">
                            <div>{{featureReq.Feature_Description__c}}</div>
                        </div>
                    </div>
                    <div class="col-12 mb-2">
                        <div class="form-group">
                            <label class="form-text">Category</label>
                            <div>{{featureReq.Category__c || '-'}}</div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="form-group">
                            <label class="form-text">Total Votes</label>
                            <div>{{featureReq.Total_Points__c}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <ng-container *ngIf="featureReqData.existingVotes && featureReqData.existingVotes.includes(featureReq.Id);else noPoints">
                                YOU VOTED <a *ngIf="featureReq.Status__c == 'Verified'" href="javascript:void(0)" class="ms-3" (click)="voteFeatureReq(featureReq.Id, true)">Undo</a>
                            </ng-container>
                            <ng-template #noPoints>
                                <ng-container *ngIf="!featureReqData.remainingFeaturePoints;else vote">
                                    You ran out of votes
                                </ng-container>
                            </ng-template>
                            <ng-template #vote>
                                <button *ngIf="featureReq.Status__c == 'Verified'" class="btn btn-primary" (click)="voteFeatureReq(featureReq.Id, false)">Vote</button>
                            </ng-template>
                            <ng-container *ngIf="featureReq.Status__c != 'Verified'">
                                <div class="mt-2">This feature is {{featureReq.Status__c}}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                    (click)="hideFeatureReqDialog()">Close</button>
            </div>
        </div>
    </div>
</div>
