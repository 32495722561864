<div class="container my-3">
    <div *ngIf='!isShowVersionPatches'>
        <h3>AvSight Releases</h3>
        <hr>
        <div *ngIf="userDetails.Support_Representative__c">
            <span role="button" class="text-primary" (click)="showCreateRequest()">CLICK HERE TO REQUEST AN UPGRADE</span>
            <p>It is important to know that all upgrades include the prior upgrades, features, and fixes. 
                You can skip any version and go right to our latest wihtout losing any features or functionality.</p>
            <hr>
        </div>
        <h3 class="mb-1">Currently Supported Versions of AvSight</h3>
        <p>Click a version name to see detailed release notes and patch history.</p>
        <ul>
            <li *ngFor="let version of getVersionKeys(releaseNotesData.configVersions); let i = index" [attr.data-index]="i">
                <span role="button" class="text-primary" (click)="viewVersionHistory(version)">{{releaseNotesData.configVersions[version].name}}</span> ({{releaseNotesData.configVersions[version].releaseDate}})
            </li>
        </ul>
        <h3 class="mt-4 mb-1">Last 50 changes to AvSight</h3>
        <p>Remember, patches are automatically applied to your system within a week of being fixed.</p>
        <ol>
            <li *ngFor="let item of recentReleaseNotes; let i = index" [attr.data-index]="i">
                <b>
                    {{releaseNotesData.configVersions[item.Fix_Version__c].name}} 
                    <span [attr.class]="item.Issue_Type__c == 'Patch' ? 'text-brown' : (item.Issue_Type__c == 'Bug' ? 'text-red' : 'text-success')">{{item.Issue_Type__c == 'Patch' ? 'Feature Patch' : (item.Issue_Type__c == 'Bug' ? 'Bug Fix' : 'New Feature')}}</span>, 
                    {{item.Release_Note_Section__c}}, {{item.Jira_Number__c}}
                </b>
                <p>{{item.Release_Notes__c}}</p>
            </li>
        </ol>
    </div>

    <div *ngIf='isShowVersionPatches'>
        <div class="row">
            <div class="col-12 px-3 py-2">
                <h4 class="mt-1 mb-2"><i role="button" class="fa-solid fa-arrow-left" (click)="hideVersionHistory()"></i> <b class="ms-3">AvSight version {{selectedVersionData.versionInfo.name}}</b></h4>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div *ngFor="let section of getVersionKeys(releaseNotesMap);" class="note-item mb-2 card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="idea-subtitle mt-1 mb-2">
                                    {{section}}
                                </div>
                                <ul class="mb-0">
                                    <li *ngFor="let item of releaseNotesMap[section]; let i = index" class="idea-desc">
                                        <b>
                                            <span [attr.class]="item.Issue_Type__c == 'Patch' ? 'text-brown' : (item.Issue_Type__c == 'Bug' ? 'text-red' : 'text-success')">{{item.Issue_Type__c == 'Patch' ? 'Feature Patch' : (item.Issue_Type__c == 'Bug' ? 'Bug Fix' : 'New Feature')}}</span>, 
                                            {{item.Jira_Number__c}}
                                        </b>
                                        <p [attr.class]="(i + 1) != releaseNotesMap[section].length ? 'mb-2' : 'mb-0'">{{item.Release_Notes__c}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- create update request -->
<div class="modal fade" id="createRequestModal" role="dialog" aria-labelledby="createRequestModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createRequestModalLabel">New Update Request</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideCreateRequest()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="requestForm" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Version</label>
                                <select class="form-select" formControlName="TargetVersion" name="TargetVersion">
                                    <option selected value="">--Select Version--</option>
                                    <option *ngFor="let version of getVersionKeys(releaseNotesData.configVersions);" value="{{version}}">{{releaseNotesData.configVersions[version].name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">SB Requested Date</label>
                                <input type="date" class="form-control" formControlName="SBRequestedDate" name="SBRequestedDate">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Prod Requested Date</label>
                                <input type="date" class="form-control" formControlName="ProdRequestedDate" name="ProdRequestedDate">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Comments</label>
                                <textarea type="text" class="form-control" formControlName="Comments" name="Comments"></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                    (click)="hideCreateRequest()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="createRequest()">Save</button>
            </div>
        </div>
    </div>
</div>