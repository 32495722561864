import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-releasenotes',
  templateUrl: './releasenotes.component.html',
  styleUrl: './releasenotes.component.css'
})
export class ReleasenotesComponent implements OnInit {
  releaseNotesData: any = {
    versionList: []
  };
  recentReleaseNotes: any = [];
  releaseNotesMap: any = {};
  releaseData: any = {};
  userDetails: any = {};
  isShowVersionPatches: any = false;
  requestForm:any = FormGroup;
  selectedVersionData: any = {};

  ngOnInit(): void {
    let self = this;
    commons.loader.show();
    self.getRequiredData(function() {
      self.getRecentReleaseNotes();
    });

    self.initRequestForm();
    $("#createRequestModal").on('hidden.bs.modal', function() {
      self.requestForm.reset();
      self.requestForm.updateValueAndValidity();
    });
  }

  initRequestForm() {
    this.requestForm = new FormGroup({
      SBRequestedDate: new FormControl('', []),
      TargetVersion: new FormControl('', []),
      ProdRequestedDate: new FormControl('', []),
      Comments: new FormControl('', [])
    });
  }

  getEmailFromUrl() {
    return commons.getUserEmail();
  }

  getRequiredData(callback: any) {
    var self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    commons.jqueryAjax(environment.BASE_URL + "releasenotes/requiredlist", { data: JSON.stringify(param) }, function (res: any) {
      if (res.status == 1) {
        let configVersions = res.data.configVersions || [];
        let configVersionMap: any = {};
        configVersions.forEach((ver: any) => {
          configVersionMap[ver.fixVersion] = ver;
        });
        self.releaseNotesData.configVersions = configVersionMap;
        self.releaseNotesData.versionList = res.data.versions || [];
        self.userDetails = res.data.contact || {};
      }
      if (callback) {
        callback();
      } else {
        commons.loader.hide();
      }
    });
  }

  getRecentReleaseNotes() {
    var self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    commons.jqueryAjax(environment.BASE_URL + "releasenotes/recentlist", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.recentReleaseNotes = res.data.releaseNotes;
    });
  }

  viewVersionHistory(version: any) {
    let self = this;
    var param: any = {};
    param.searchText = '';
    param.version = version;
    param.email = this.getEmailFromUrl();
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "releasenotes/getlist", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      let sectionMap: any = {};
      res.data.releaseNotes.forEach((item: any) => {
        if (!sectionMap.hasOwnProperty(item.Release_Note_Section__c)) {
          sectionMap[item.Release_Note_Section__c] = [];
        }
        sectionMap[item.Release_Note_Section__c].push(item);
      })
      self.releaseNotesMap = sectionMap;
      self.selectedVersionData = {
        versionInfo: self.releaseNotesData.configVersions[version]
      };
      self.isShowVersionPatches = true;
    });
  }

  hideVersionHistory() {
    this.isShowVersionPatches = false;
    this.selectedVersionData = {};
    this.releaseNotesMap = {};
  }

  showCreateRequest() {
    this.initRequestForm();
    $("#createRequestModal").modal("show");
  }

  hideCreateRequest() {
    $("#createRequestModal").modal("hide");
  }

  createRequest() {
    if (!this.requestForm.valid) {
      return;
    }
    var self = this;
    var param = self.requestForm.value;
    param.SBRequestedDate = param.SBRequestedDate || null;
    param.ProdRequestedDate = param.ProdRequestedDate || null;
    if (!param.TargetVersion) {
      commons.notification.show({
        type: "error",
        msg: "Please select version.",
        timeout: 3000
      });
      return;
    }
    if (!param.Comments) {
      commons.notification.show({
        type: "error",
        msg: "Please enter comments.",
        timeout: 3000
      });
      return;
    }
    param.email = this.getEmailFromUrl();
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "updaterequest/create", { data: JSON.stringify(param) }, function(res: any) {
      commons.loader.hide();
        if (res.status != 1) {
            commons.notification.show({
              type: "error",
              msg: res.message,
              timeout: 3000
            });
            return;
        }
        commons.notification.show({
          type: "success",
          msg: "Upgrade request submitted successfully.",
          timeout: 3000
        });
        $("#createRequestModal").modal("hide");
    });
  }

  getVersionKeys(versionMap: any) {
    if (!versionMap) {
      return [];
    }
    return Object.keys(versionMap);
  }
}
