<div [class]="isFullWidthPage ? 'main-container full-width' : 'main-container'">
    <router-outlet *ngIf="isVerified"></router-outlet>
    <div *ngIf="loginMessage && loginMessage.length > 0" class="message-box">
        <div class="d-flex justify-content-center align-items-center">
            {{loginMessage}}
        </div>
    </div>
    <div class="row mx-2 my-3" *ngIf="showEmailInput">
        <div class="col-md-12">
            <h5>Welcome to AvSight Copilot</h5>
            <p>To use Copilot AI enter your email address below. An account will be created for you to gain access to feature voting, AI Knowledge Base assistance, release notes, and more.</p>
            <div class="row mt-4">
                <div class="col-12 mb-4">
                    <h6>Your Email</h6>
                    <input class="form-control" type="email" maxlength="255" [(ngModel)]="newUserData.email">
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-primary btn-block"(click)="verifyEmail()">Continue</button>
                </div>
            </div>
        </div>
        <div class="col-12 support-container">
            <h6 class="card-subtitle mb-2 text-muted" role="button" (click)="onExpandContactSupport($event)">Additional Resources <i class="expand-icon ps-1 fa-solid fa-chevron-right"></i></h6>
            <div class="row collapsible">
                <div class="col-6 ps-3">
                    <i class="fa-solid fa-lightbulb"></i> <span class="text-decoration-underline ps-3" role="button" (click)="openKB()">Knowledge Base</span>
                </div>
                <div class="col-6">
                    <span class="merge-icons">
                        <i class="fa-regular fa-user"></i>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 0C28.7 0 0 28.7 0 64L0 352c0 35.3 28.7 64 64 64l176 0-10.7 32L160 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l256 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-69.3 0L336 416l176 0c35.3 0 64-28.7 64-64l0-288c0-35.3-28.7-64-64-64L64 0zM512 64l0 224L64 288 64 64l448 0z"/></svg>
                    </span>
                    <span class="text-decoration-underline ps-1" role="button" (click)="openPortal()">Support Portal</span>
                </div>
                <div class="col-6 ps-3 pt-2">
                    <i class="fa-solid fa-graduation-cap"></i> <span class="text-decoration-underline ps-2" role="button" (click)="openUniversity()">AvSight University</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mx-2 my-3" *ngIf="showAccessCode">
        <div class="col-md-12">
            <p>A 4 digit access code was sent to the email address you entered. Enter that code below to continue.</p>
            <div class="row mt-4">
                <div class="col-12 mb-4">
                    <h6>Access Code</h6>
                    <input class="form-control" type="text" maxlength="4" [(ngModel)]="newUserData.accessCode">
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-primary me-2 btn-block"(click)="backToEmailInput()">Back</button>
                    <button type="button" class="btn btn-primary btn-block"(click)="verifyCode()">Continue</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mx-2 my-3" *ngIf="showPreferences">
        <div class="col-md-12">
            <h5>Welcome to AvSight Copilot</h5>
            <p>In order to serve you better please fill out the following short survey. This information will help us to create an account for you and provide relevant information tailored to your role.</p>
            <div class="row mt-4">
                <div class="col-12 mb-3">
                    <h6>Company</h6>
                    <input class="form-control" type="text" maxlength="255" [(ngModel)]="preferenceData.contact.companyName">
                </div>
                <div class="col-12 mb-3">
                    <input class="form-check-input" type="checkbox" [value]="preferenceData.isSupportRep" id="supportRep">
                    <label class="form-check-label ps-1" for="supportRep">
                        I am the AvSight Support Contact
                    </label>
                </div>
                <div class="col-12 mb-4">
                    <h6>Your Name</h6>
                    <input class="form-control" type="text" maxlength="255" [(ngModel)]="preferenceData.contact.name">
                </div>
                <div class="col-12 mb-4">
                    <h6>Your Job Title</h6>
                    <input class="form-control" type="text" maxlength="255" [(ngModel)]="preferenceData.jobTitle">
                </div>
                <div class="col-12 mb-4">
                    <h6>Contact #</h6>
                    <input class="form-control" type="text" maxlength="255" [(ngModel)]="preferenceData.contact.phone">
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Role</h6>
                    <div *ngFor="let role of preferenceData.roles; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="role" [checked]="preferenceData.selRoles && preferenceData.selRoles.indexOf(role) != -1" [id]="'role' + i">
                        <label class="form-check-label ps-1" [for]="'role' + i">
                            {{role}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Seniority</h6>
                    <div *ngFor="let seniority of preferenceData.seniorities; let i = index">
                        <input class="form-check-input" type="radio" name="seniority" [value]="seniority" [checked]="preferenceData.selSenioirty && preferenceData.selSenioirty == seniority" [id]="'seniority' + i">
                        <label class="form-check-label ps-1" [for]="'seniority' + i">
                            {{seniority}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Notification Preferences</h6>
                    <div *ngFor="let pref of preferenceData.notificationPreferences; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="pref" [checked]="preferenceData.selNotification && preferenceData.selNotification.indexOf(pref) != -1" [id]="'pref' + i">
                        <label class="form-check-label ps-1" [for]="'pref' + i">
                            {{pref}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Business Segments</h6>
                    <div *ngFor="let seg of preferenceData.businessSegments; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="seg" [checked]="preferenceData.selBusinessSegments && preferenceData.selBusinessSegments.indexOf(seg) != -1" [id]="'seg' + i">
                        <label class="form-check-label ps-1" [for]="'seg' + i">
                            {{seg}}
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-primary btn-block"(click)="updatePreferences()">Save and Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>