import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isDevMode } from '@angular/core';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-copilot',
  templateUrl: './copilot.component.html',
  styleUrl: './copilot.component.css',
  encapsulation: ViewEncapsulation.None
})
export class CopilotComponent implements OnInit {

  chatHistory: any = [];
  chatStats: any = {};
  chatRecentHistory: any = [];
  chatRecentHistoryAll: any = [];
  questionText: any = '';
  maxQuestionPerSession: any = 5;
  questionCount: any = 1;
  hasEmail: any = false;
  isShowRecentHistory: any = false;
  feedbackId: any = '';
  chatSessionHistory: any = [];
  isKBLoading: any = false;
  isAILoading: any = false;
  isShowMore: any = false;
  isShowFeatureRequests = false;
  isValidUser = false;
  historyFilter = 'askAI';
  reqStatus: any = '';
  isShowReleaseNotes = false;
  hideTitle = false;
  isShowUsageStatistics = false;
  hasUsageStats = false;
  usageStats: any = [];
  featureReqData: any = {};
  userDetails: any = {};
  betaFeatures: any = {};

  ngOnInit(): void {
    this.hideTitle = commons.getUrlParameter('hideTitle') == 'true';
    let email = this.getEmailFromUrl();
    this.hasEmail = email && email.trim().length > 0;
    var onWindowResize = function() {
      $(".chat-container").css({"position": "relative", "height": ""});
      let height = $('.chat-container .support-container').outerHeight();
      $(".chat-container .search-section").css('margin-bottom', (height + 20) + 'px');
      $(".chat-container .recent-section").css('margin-bottom', height + 'px');
    };
    setTimeout(function() {
      onWindowResize();
    }, 100);
    $(window).on("resize", onWindowResize);

    $("#copilotFeedback").on('hidden.bs.modal', function() {
      $("#copilotFeedback .feedback-message").val("");
    });

    //get request status set from app component
    let reqData: any = localStorage.getItem('reqData');
    this.reqStatus = JSON.parse(reqData).status;
    this.hasUsageStats = JSON.parse(reqData).email2quote;
    localStorage.removeItem('reqData');

    if (this.hasEmail && this.reqStatus != 'Requested') {
      this.getChatStats();
      this.getUserDetails();
    }
  }

  getEmailFromUrl() {
    return commons.getUserEmail();
  }

  hadleStartOver() {
    this.questionCount = 1;
    this.chatSessionHistory = [];
    this.isShowRecentHistory = false;
    $(window).trigger("resize");
  }

  hadleSearch(event: any, isKB: any) {
    if (event.shiftKey) {
      return;
    }
    if (this.questionText.trim().length == 0) {
      event.preventDefault();
      this.questionText = "";
      commons.notification.show({
        type: "error",
        msg: "Please enter search question to continue.",
        timeout: 3000
      });
      return;
    }
    if (this.questionCount > this.maxQuestionPerSession) {
      this.questionCount = 1;
    }
    this.questionText = this.questionText.trim();
    let self = this;
    isKB ? this.isKBLoading = true : this.isAILoading = true;
    let param = {q: self.questionText, newSession: self.questionCount == 1, email: this.getEmailFromUrl(), isKB: isKB};
    self.questionText = "";
    commons.jqueryAjax(environment.BASE_URL + "copilot/search", { data: JSON.stringify(param) }, function (res: any) {
      isKB ? self.isKBLoading = false : self.isAILoading = false;
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        self.hadleStartOver();
        return;
      }
      if (isKB) {
        res.data.articles.forEach((article: any)=>{
          article.link = res.data.url + article.UrlName;
        });
        self.chatSessionHistory = [{
          user_query: param.q,
          answers: res.data.articles,
          isExpanded: true,
          isKB: true
        }];
        self.isShowRecentHistory = false;
        self.questionCount = 1;
      } else {
        if (self.questionCount == 1) {
          self.chatSessionHistory = [];
          self.isShowRecentHistory = false;
        }
        self.chatSessionHistory.unshift({
          user_query: param.q,
          answer: self.parseResponseForLink(res.data),
          isExpanded: true
        });
        self.questionCount++;
      }
      setTimeout(function() {
        $(".recent-section").animate({ scrollTop: $(".recent-section").prop("scrollHeight")}, 700);
      }, 100);
      //commons.trackActivity(param.q);
      self.getChatStats();
    });
  }

  getChatStats() {
    let self = this;
    let param = {email: this.getEmailFromUrl()};
    commons.jqueryAjax(environment.BASE_URL + "copilot/stats", {data: JSON.stringify(param)}, function (res: any) {
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.chatStats = res.data;
    });
  }

  getUserDetails() {
    let self = this;
    let param = {email: this.getEmailFromUrl()};
    commons.jqueryAjax(environment.BASE_URL + "user/details", {data: JSON.stringify(param)}, function (res: any) {
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.userDetails = res.data;
      $(window).trigger("resize");
    });
  }

  onExpandChat(event: any) {
    $(event.currentTarget).toggleClass("text-truncate");
  }

  onExpandContactSupport(event: any) {
    $(".support-container .collapsible").toggleClass("d-none");
    $(".support-container .expand-icon").toggleClass("rotate");
    $(window).trigger("resize");
  }

  onShowRecentHistory(event: any) {
    this.hadleHistoryClick();
  }

  onHideRecentHistory(event: any) {
    this.isShowRecentHistory = false;
    $(window).trigger("resize");
  }

  openKB() {
    if (isDevMode()) {
      window.open('https://inscaletech--internalsw.sandbox.my.site.com/support/s/knowledgebase', '_blank');
    } else {
      window.open('https://inscaletech.my.site.com/support/s/knowledgebase', '_blank');
    }
    this.hadleStartOver();
  }

  openPortal() {
    if (isDevMode()) {
      let portalUrl = 'https://avscpd.eastus.cloudapp.azure.com:8080/?email=' + this.getEmailFromUrl();
      portalUrl += '&code=' + (commons.getAccessCode() || '');
      window.open(portalUrl, '_blank');
    } else {
      window.open('https://inscaletech.my.site.com/support/s/', '_blank');
    }
    this.hadleStartOver();
  }

  openReleaseNotes() {
    this.isShowReleaseNotes = true;
    this.hadleStartOver();
  }
  hideReleaseNotes() {
    this.isShowReleaseNotes = false;
    this.hadleStartOver();
  }

  openUsageStatistics() {
    this.hadleStartOver();
    this.getEmail2QuoteStats();
  }
  hideUsageStatistics() {
    this.isShowUsageStatistics = false;
    this.hadleStartOver();
  }
  getEmail2QuoteStats() {
    let self = this;
    commons.loader.show();
    let param = {email: this.getEmailFromUrl()};
    commons.jqueryAjax(environment.BASE_URL + "email2quote/usagestats", {data: JSON.stringify(param)}, function (res: any) {
      commons.loader.hide();
      if (res.status == 1) {
        self.usageStats = res.data;
      }
      self.isShowUsageStatistics = true;
    });
  }

  openUniversity() {
    window.open('https://support.avsight.net/', '_blank');
    this.hadleStartOver();
  }

  openSupport() {
    if (isDevMode()) {
      window.open('https://inscaletech--internalsw.sandbox.my.site.com/support/s/contactsupport', '_blank');
    } else {
      window.open('https://inscaletech.my.site.com/support/s/contactsupport', '_blank');
    }
    this.hadleStartOver();
  }

  hadleHistoryClick() {
    let self = this;
    let param = {email: this.getEmailFromUrl(), type: self.historyFilter};
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "copilot/history", {data: JSON.stringify(param)}, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      if (self.historyFilter == 'askAI' && res.data) {
        res.data.forEach((item: any, indx: any)=>{
          item.answer = self.parseResponseForLink(item.answer);
        });
      }
      self.chatRecentHistoryAll = JSON.parse(JSON.stringify(res.data));
      self.chatRecentHistory = res.data.slice(0, 20);
      self.isShowMore = res.data.length > 20;
      self.isShowRecentHistory = true;
      setTimeout(function() {
        $(window).trigger("resize");
      }, 100);
    });
  }

  hadleNewSearch() {
    this.questionCount = 1;
    this.chatHistory = [];
  }

  handleShowMore() {
    let chatRecentHistoryAll = this.chatRecentHistoryAll;
    this.chatRecentHistory = chatRecentHistoryAll;
    this.isShowMore = false;
  }

  hadleFeedbackClick() {
    $("#copilotFeedback").modal("show");
  }

  hideFeedbackModal() {
    $("#copilotFeedback").modal("hide");
    $("#copilotFeedback .feedback-message").val("");
  }

  openURLInNewWindow(url: any) {
    window.open(url, 'AvSight KB Article', 'left=20,top=20,width=900,height=600,toolbar=1,resizable=0');
  }

  submitFeedback(score: any) {
    let message = $("#copilotFeedback .feedback-message").val();
    if (!message || message.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter feedback message to continue.",
        timeout: 3000
      });
      return;
    }
    // let chatStr = '';
    // this.chatSessionHistory.forEach((chat: any) => {
    //   if (!chat.isKB) {
    //     chatStr += `User: ${chat.user_query}\n\n`;
    //     chatStr += `CoPilot: ${chat.answer}\n\n\n`;
    //   }
    // });
    let self = this;
    commons.loader.show();
    let param: any = {
      //chat: btoa(chatStr.trim()),
      message: message,
      email: this.getEmailFromUrl(),
      feedbackId: this.feedbackId,
      score: score
    };
    commons.jqueryAjax(environment.BASE_URL + "copilot/feedback", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      commons.notification.show({
        type: "success",
        msg: "Feedback submitted successfully.",
        timeout: 3000
      });
      self.hideFeedbackModal();
    });
  }
  showFeatureRequests() {
    var self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "verify", { data: JSON.stringify(param) }, function (res: any) {
      if (res.status != 1) {
        commons.loader.hide();
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      if (res.status == 1 && res.data && res.data.status == 'Requested') {
        commons.loader.hide();
        self.isShowFeatureRequests = true;
        self.isValidUser = false;
        return;
      }
      self.isValidUser = true;
      self.getFeatureReqData();
    });
  }
  hideFeatureRequests() {
    this.isShowFeatureRequests = false;
    this.isValidUser = false;
    this.hadleStartOver();
  }
  getFeatureReqData() {
    var self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    commons.jqueryAjax(environment.BASE_URL + "featurerequest/requiredlist", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      self.isShowFeatureRequests = true;
      if (res.status == 1) {
        self.featureReqData = res.data;
      }
    });
  }
  openFeatureReqWindow() {
    let url = '/feature-requests?email=' + this.getEmailFromUrl();
    window.open(url, 'AvSight Feature Requests', 'left=20,top=0,toolbar=1,resizable=0');
  }

  showHelpModal() {
    $("#copilotButtonHelp").modal("show");
  }
  hideHelpModal() {
    $("#copilotButtonHelp").modal("hide");
  }

  getFeaturePreviews(callback: any) {
    let self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "featurepreviews/getlist", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      self.betaFeatures.dataList = [];
      if (res.status == 1) {
        self.betaFeatures.dataList = res.data && res.data.featurePreviewes ? res.data.featurePreviewes : [];
      }
      if (callback) {
        callback();
      }
    });
  }
  showBetaFeatures() {
    let self = this;
    this.getFeaturePreviews(function() {
      self.betaFeatures.isList = true;
      $("#betaFeatures").modal("show");
    });
  }
  signUpForFP(isInterested: any) {
    if (isInterested) {
      this.betaFeatures.isDetail = false;
      this.betaFeatures.isComment = true;
    } else {
      this.updateInterest();
    }
  }
  hideBetaFeaturesModal() {
    $("#betaFeatures").modal("hide");
    this.betaFeatures.selIndx = null;
    this.betaFeatures.isList = false;
    this.betaFeatures.isDetail = false;
    this.betaFeatures.comment = '';
  }
  handleFeaturePrevClick(indx: any) {
    this.betaFeatures.selIndx = indx;
    this.betaFeatures.isList = false;
    this.betaFeatures.isDetail = true;
  }
  backFPDetailPage() {
    this.betaFeatures.selIndx = null;
    this.betaFeatures.isList = true;
    this.betaFeatures.isDetail = false;
    this.betaFeatures.isComment = false;
    this.betaFeatures.comment = '';
  }
  backFPCommentPage() {
    this.betaFeatures.isDetail = true;
    this.betaFeatures.isComment = false;
    this.betaFeatures.comment = '';
  }
  updateInterest() {
    let self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    param['fpId'] = self.betaFeatures.dataList[self.betaFeatures.selIndx].Id;
    if (self.betaFeatures.isComment) {
      param['comment'] = self.betaFeatures.comment;
    }
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "featurepreviews/updateinterest", { data: JSON.stringify(param) }, function (res: any) {
      if (res.status != 1) {
        commons.loader.hide();
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.getFeaturePreviews(function() {
        self.backFPDetailPage();
      });
    });
  }

  getCurrentDateTime() {
    var d = new Date();
    return d.toLocaleString([], { hour12: true});
  }
  formatDate(date: any) {
    var d = new Date(date);
    return d.toLocaleString([], { hour12: true});
  }
  parseResponseForLink(str: any) {
    let self= this;

    //check for hallucincated results
    if (str.indexOf('__HALLUCINATED__') != -1) {
      return 'Not enough information was available to answer your question completely. Our KB is being updated frequently and our technical writers have been notified about this reply. Check back soon for a better answer to your question.'
    }

    //get feedback id
    let regex = /\[FeedbackID: (.*?)\]/g;
    let matches = Array.from(str.matchAll(regex));
    let result = str;

    matches.forEach((match: any) => {
      self.feedbackId = match[1];
      result = result.replace(match[0], '');
    });
    result = result.trim();

    regex = /\[(.*?)\]\((.*?)\)/g;
    matches = Array.from(str.matchAll(regex));

    matches.forEach((match: any) => {
      const linkText = match[1];
      const url = match[2];
      const link = `<a href="${url}" target="_blank">${linkText}</a>`;
      result = result.replace(match[0], link);
    });

    return result;
  }
}
