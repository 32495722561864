import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-releasenotes',
  templateUrl: './releasenotes.component.html',
  styleUrl: './releasenotes.component.css'
})
export class ReleasenotesComponent implements OnInit {
  releaseNoteList: any = [];
  releaseData: any = {};

  ngOnInit(): void {
    this.releaseNoteList = environment.releases
  }

  getEmailFromUrl() {
    return commons.getUserEmail();
  }

  onReleaseItemClick(event: any) {
    var indx = +event.currentTarget.dataset.index;
    this.releaseData = JSON.parse(JSON.stringify(this.releaseNoteList))[indx];
    window.open(this.releaseData.article_url, 'Release Notes - ' + this.releaseData.name, 'left=20,top=20,width=900,height=600,toolbar=1,resizable=0');
  }

  hideReleaseNoteDialog() {
    $("#releaseNoteDataModal").modal("hide");
    this.releaseData = {};
  }

  openArticle() {
    window.open(this.releaseData.siteUrl + this.releaseData.articleData.UrlName, "_blank");
  }
}
