<div class="">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive mt-1" *ngIf="usageStatsList && usageStatsList.length > 0">
                <table class="table release-list">
                    <thead>
                        <tr>
                            <td>Month</td>
                            <td>Calls</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let fItem of usageStatsList; let i = index" [attr.data-index]="i">
                            <td class="text-truncate truncate">{{fItem.month}}-{{fItem.year}}</td>
                            <td class="text-truncate truncate">{{fItem.usage}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>