<div class="">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive mt-1" *ngIf="releaseNoteList && releaseNoteList.length > 0">
                <table class="table release-list">
                    <tbody>
                        <tr *ngFor="let fItem of releaseNoteList; let i = index" [attr.data-index]="i" (click)="onReleaseItemClick($event)">
                            <td class="text-truncate truncate" role="button">{{fItem.name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- release info -->
<div class="modal fade" id="releaseNoteDataModal" role="dialog" aria-labelledby="releaseNoteDataModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="featureReqDataModalLabel">
                    {{releaseData.name}}
                    <a class="open-link" target="_top" href="javascript:void(0)" (click)="openArticle()">Open in new window</a>
                </h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideReleaseNoteDialog()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div [innerHTML]="releaseData.articleData ? releaseData.articleData.KB_Content__c : ''"></div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                    (click)="hideReleaseNoteDialog()">Close</button>
            </div>
        </div>
    </div>
</div>