<div *ngIf="!hasEmail" class="container mt-4 mb-0">
    <p class="text-center">Access denied</p>
</div>
<div *ngIf="hasEmail" class="container mt-0 chat-container">
    <div *ngIf="!isShowFeatureRequests && !isShowReleaseNotes && !isShowUsageStatistics" class="row">
        <div class="col-12 p-0">
            <div [attr.class]="isShowRecentHistory ? 'd-none' : 'search-section'">
                <div *ngIf="!hideTitle" class="row">
                    <div class="col-6">
                        <h5 class="mt-2 mb-0">AvSight Copilot</h5>
                    </div>
                    <div class="col-6 text-end">
                        <h5 class="mt-2 mb-0" title="beta version; occasional errors may occur">Beta</h5>
                    </div>
                </div>
                <div class="search-container mt-2 pb-3">
                    <textarea type="text" class="form-control" rows="3" [(ngModel)]="questionText" [attr.placeholder]="questionCount == 1 ? 'Search or ask a question...' : 'Continue question or start over...'"></textarea>
                    <div *ngIf="questionCount <= maxQuestionPerSession" class="btn-container pb-0">
                        <button *ngIf="questionCount == 1" class="btn btn-outline-secondary" type="button" (click)="hadleSearch($event, true)" [attr.disabled]="isKBLoading || isAILoading ? true : null"><img src="../../../assets/images/chat-kb.png" width="22" /> {{isKBLoading ? 'Working...' : 'Search KB'}}</button>
                        <button *ngIf="questionCount > 1" class="btn btn-outline-secondary" type="button" (click)="hadleStartOver()" [attr.disabled]="isAILoading ? true : null">{{isAILoading ? 'Working...' : 'Start Over'}}</button>
                        <span>&nbsp;</span>
                        <button class="btn btn-outline-secondary px-1 me-2" type="button" (click)="hadleSearch($event, false)" [attr.disabled]="isKBLoading || isAILoading ? true : null"><img src="../../../assets/images/chat-gpt.png" width="22" /> {{isAILoading ? 'Working...' : questionCount > 1 ? ('Dig Deeper (' + (questionCount - 1) + '/' + maxQuestionPerSession + ')') : 'Ask AI'}}</button>
                        <i class="fa-solid fa-circle-question" (click)="showHelpModal()"></i>
                    </div>
                    <div *ngIf="questionCount > maxQuestionPerSession" class="btn-container pb-0">
                        <button class="btn btn-outline-secondary w-100" type="button" (click)="hadleStartOver()">Start Over</button>
                    </div>
                    <ng-container *ngIf="chatSessionHistory && chatSessionHistory.length > 0">
                        <h6 *ngIf='!chatSessionHistory[0].isKB' class="text-secondary px-3 mt-3 mb-1">
                            <a href="javascript:void(0)" class="text-decoration-none" (click)="hadleFeedbackClick()">Submit Feedback for this AI response</a>
                        </h6>
                        <div [attr.class]="chatSessionHistory[0].isKB ? 'card mt-2 history-item border-0' : 'card history-item border-0'">
                            <ng-container *ngFor="let chat of chatSessionHistory; let i = index">
                                <div [attr.class]="chat.date_time ? 'card-body py-2' : 'card-body py-2'">
                                    <!-- <div class="row">
                                        <div class="col-12">
                                            <p *ngIf="chat.date_time" class="chat-time">{{formatDate(chat.date_time)}}</p>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-1">
                                            <img *ngIf="chat.isKB" src="../../../assets/images/chat-kb.png" width="22" />
                                            <img *ngIf="!chat.isKB" src="../../../assets/images/chat-gpt.png" width="22" />
                                        </div>
                                        <div class="col-11">
                                            <h6 class="'card-subtitle mb-1 text-muted"><b>{{(chat.isKB ? 'Searched KB for ' : '') + chat.user_query}}</b></h6>
                                            <ng-container *ngIf="chat.isKB">
                                                <ul class="kb-links">
                                                    <li *ngFor="let answer of chat.answers;">
                                                        <a href="javascript:void(0)" (click)="openURLInNewWindow(answer.link)">{{answer.Title}}</a>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!chat.isKB">
                                                <div class="answer-text" [innerHTML]="chat.answer"></div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="!chatSessionHistory || chatSessionHistory.length == 0" class="mt-3">
                    <div><b>There are two ways to find answers in Copilot</b></div>
                    <div class="mt-3"><b>Search KB</b> is a traditional keyword search.  This will list for you any KB articles that contains the word you search for.</div>
                    <div class="mt-3"><b>Ask AI</b> lets you can carry on a natural language search. Simply ask a question and Copilot will provide you a “GPT Style” answer.</div>
                </div>
            </div>

            <div *ngIf='isShowRecentHistory' class="recent-section pt-0">
                <div class="row">
                    <h5 *ngIf="!hideTitle" class="pt-1 mt-2 mb-0">AvSight Copilot</h5>
                    <div class="col-12 px-3 py-2">
                        <h4 class="mt-1 mb-2"><i class="fa-solid fa-arrow-left" (click)="onHideRecentHistory($event)"></i> <b class="ms-3">Recent History</b></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9">
                        <select class="form-select" [(ngModel)]="historyFilter">
                            <option value="searchKB">Search KB</option>
                            <option value="askAI" selected>Ask AI</option>
                        </select>
                    </div>
                    <div class="col-3 text-end">
                        <button class="btn btn-primary" (click)="hadleHistoryClick()">Filter</button>
                    </div>
                </div>
                <div *ngIf="chatRecentHistory && chatRecentHistory.length > 0" class="mt-3">
                    <ng-container *ngFor="let chat of chatRecentHistory; let i = index">
                        <div class="card history-item mb-2">
                            <div [attr.class]="chat.date_time ? 'card-body pt-0 pb-2' : 'card-body pb-2'">
                                <div class="row">
                                    <div class="col-12">
                                        <p *ngIf="chat.date_time" class="chat-time">{{formatDate(chat.date_time)}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-1">
                                        <img *ngIf="chat.isKB" src="../../../assets/images/chat-kb.png" width="22" />
                                        <img *ngIf="!chat.isKB" src="../../../assets/images/chat-gpt.png" width="22" />
                                    </div>
                                    <div class="col-11">
                                        <h6 class="'card-subtitle mb-1 text-muted"><b>{{(chat.isKB ? 'Searched KB for ' : '') + chat.user_query}}</b></h6>
                                        <ng-container *ngIf="chat.isKB">
                                            <ul class="kb-links">
                                                <li *ngFor="let answer of chat.answerLinks;">
                                                    <a href="javascript:void(0)" (click)="openURLInNewWindow(answer.link)">{{answer.title}}</a>
                                                </li>
                                            </ul>
                                        </ng-container>
                                        <ng-container *ngIf="!chat.isKB">
                                            <div [attr.class]="chat.isExpanded ? 'collapse' : 'collapse text-truncate'" [innerHTML]="chat.answer" (click)="onExpandChat($event)"></div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row mt-1" *ngIf='isShowMore'>
                        <div class="col-6"></div>
                        <div class="col-6 clearfix">
                            <a href="javascript:void(0)" class="me-2 float-end text-decoration-none" (click)="handleShowMore()">Show More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 support-container">
            <div>
                <h6 class="card-subtitle mb-2 text-muted cur" role="button" (click)="onExpandContactSupport($event)">Additional Resources <i class="expand-icon ps-1 fa-solid fa-chevron-right"></i></h6>
                <div class="row collapsible">
                    <div class="col-6 ps-3">
                        <div class="row">
                            <div class="col-12">
                                <i class="fa-solid fa-lightbulb"></i> <span class="text-decoration-underline ps-2" role="button" (click)="openKB()">Knowledge Base</span>
                            </div>
                            <div class="col-12 pt-2">
                                <i class="fa-solid fa-clipboard"></i> <span class="text-decoration-underline ps-2" role="button" (click)="openReleaseNotes()">Release Notes</span>
                            </div>
                            <div class="col-12 pt-2">
                                <i class="fa-solid fa-square-poll-vertical"></i> <span class="text-decoration-underline ps-2" role="button" (click)="showFeatureRequests()">Feature Voting</span>
                            </div>
                            <div class="col-12 pt-2" *ngIf="hasUsageStats">
                                <i class="fa-solid fa-exchange-alt"></i> <span class="text-decoration-underline ps-2" role="button" (click)="openUsageStatistics()">Email 2 Quote</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12" *ngIf="userDetails && userDetails.Support_Representative__c">
                                <span class="merge-icons">
                                    <i class="fa-regular fa-user"></i>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 0C28.7 0 0 28.7 0 64L0 352c0 35.3 28.7 64 64 64l176 0-10.7 32L160 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l256 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-69.3 0L336 416l176 0c35.3 0 64-28.7 64-64l0-288c0-35.3-28.7-64-64-64L64 0zM512 64l0 224L64 288 64 64l448 0z"/></svg>
                                </span>
                                <span class="text-decoration-underline ps-1" role="button" (click)="openPortal()">Support Portal</span>
                            </div>
                            <div class="col-12 pt-2">
                                <i class="fa-solid fa-graduation-cap"></i> <span class="text-decoration-underline ps-1" role="button" (click)="openUniversity()">AvSight University</span>
                            </div>
                            <div class="col-12 pt-2" *ngIf = '!isShowRecentHistory'>
                                <i class="fa-solid fa-history"></i> <span class="text-decoration-underline ps-2" role="button" (click)="onShowRecentHistory($event)">Recent History</span>
                            </div>
                            <div class="col-12 pt-2" *ngIf = 'isShowRecentHistory'>
                                <i class="fa-solid fa-history"></i> <span class="text-decoration-underline ps-2" role="button" (click)="onHideRecentHistory($event)">Hide History</span>
                            </div>
                            <div class="col-12 pt-2" *ngIf="userDetails && userDetails.Support_Representative__c">
                                <i class="fa-solid fa-stream"></i> <span class="text-decoration-underline ps-2" role="button" (click)="showBetaFeatures()">Beta Features</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="reqStatus != 'Requested'" class="col-12 stat-info">
                        YOU USED COPILOT {{chatStats.total_sessions || 0}} TIME(S) AND ASKED {{chatStats.total_requests || 0}} QUESTION(S) IN THE LAST 30 DAYS
                    </div>
                    <div *ngIf="reqStatus == 'Requested'" class="col-12 stat-info">
                        SOME FEATURES ARE UNAVAILABLE WHILE ACCOUNT IS BEING VERIFIED
                    </div>
                </div>
            </div>
            <div class="bottom-container mt-2" *ngIf="userDetails && userDetails.Support_Representative__c">
                <button class="btn btn-outline-secondary w-100" type="button" (click)="openSupport()">Contact Support</button>
            </div>
        </div>
    </div>

    <div *ngIf="isShowFeatureRequests" class="px-1">
        <div class="row">
            <h5 *ngIf="!hideTitle" class="pt-1 mt-2 mb-0">AvSight Copilot</h5>
            <div class="col-12 px-3 py-2">
                <h4 class="mt-1 mb-2"><i class="fa-solid fa-arrow-left" (click)="hideFeatureRequests()"></i> <b class="ms-3">Feature Voting</b></h4>
            </div>
            <div *ngIf="!isValidUser" class="col-12 py-2">
                <p class="text-center">
                    Once your account has been verified by an AvSight employee, you will be able to view and vote on Feature Requests.
                </p>
            </div>
            <div *ngIf="isValidUser" class="col-12 py-2 text-center">
                <p>Feature Requests come from the entire AvSight User Community. They are created by our product owners who receive support tickets, change requests, and email messages from you. Feature Voting lets you participate in the AvSight product roadmap by upvoting ideas. This helps AvSight's Product Owners to focus on the features that are most important to our user community.</p>
                <p *ngIf="!featureReqData.remainingFeaturePoints">You have no remaining votes</p>
                <p *ngIf="featureReqData.remainingFeaturePoints && featureReqData.remainingFeaturePoints > 0">
                    You have {{featureReqData.remainingFeaturePoints}} feature {{featureReqData.remainingFeaturePoints == 1 ? 'vote' : 'votes'}} remaining
                </p>
                <a href="javscript:void(0)" (click)="openFeatureReqWindow()">View Feature Requests</a>
                <!-- <app-featurerequests></app-featurerequests> -->
            </div>
        </div>
    </div>

    <div *ngIf="isShowReleaseNotes" class="px-1">
        <div class="row">
            <h5 *ngIf="!hideTitle" class="pt-1 mt-2 mb-0">AvSight Copilot</h5>
            <div class="col-12 px-3 py-2">
                <h4 class="mt-1 mb-2"><i class="fa-solid fa-arrow-left" (click)="hideReleaseNotes()"></i> <b class="ms-3">Release Notes</b></h4>
            </div>
            <div class="col-12 py-2">
                <app-releasenotes></app-releasenotes>
            </div>
        </div>
    </div>

    <div *ngIf="isShowUsageStatistics" class="px-1">
        <div class="row">
            <h5 *ngIf="!hideTitle" class="pt-1 mt-2 mb-0">Email 2 Quote</h5>
            <div class="col-12 px-3 py-2">
                <h4 class="mt-1 mb-2"><i class="fa-solid fa-arrow-left" (click)="hideUsageStatistics()"></i> <b class="ms-3">Email 2 Quote</b></h4>
            </div>
            <div class="col-12 py-2">
                <app-usagestatistics [usageStatsList]="usageStats"></app-usagestatistics>
            </div>
        </div>
    </div>

    <!-- send copilot feedback -->
    <div class="modal fade" id="copilotFeedback" role="dialog" aria-labelledby="copilotFeedbackLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="copilotFeedbackLabel">Send Feedback</h5>
                    <button class="close" type="button" (click)="hideFeedbackModal()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-text">Message</div>
                            <textarea type="text" class="form-control feedback-message" rows="8" placeholder="Type your message..."></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" (click)="submitFeedback(1)"><i class="fa-solid fa-thumbs-up"></i> Like It</button>
                    <button class="btn btn-primary" type="button" (click)="submitFeedback(0)"><i class="fa-solid fa-thumbs-down"></i> Needs Work</button>
                </div>
            </div>
        </div>
    </div>

    <!-- show button help text -->
    <div class="modal fade" id="copilotButtonHelp" role="dialog" aria-labelledby="copilotButtonHelpLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="copilotButtonHelpLabel">Help</h5>
                    <button class="close" type="button" (click)="hideHelpModal()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="my-2">Search KB</h5>
                            <p class="mb-4">Use this button to look up information in our knowledge base. Just type in a few keywords related to your question, and we'll find all the articles that match those keywords.</p>
                            <h5 class="mb-2">Ask AI</h5>
                            <p class="mb-2">Use this button to get answers directly from our AI assistant. Type in your question in natural language, and our AI will provide you with a helpful response based on all the information available.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                        (click)="hideHelpModal()">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- beta feature list -->
    <div class="modal fade" id="betaFeatures" role="dialog" aria-labelledby="betaFeaturesLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="betaFeaturesLabel">
                        <span *ngIf="betaFeatures.isList">Feature Previews</span>
                        <span *ngIf="betaFeatures.isDetail">Feature Details</span>
                        <span *ngIf="betaFeatures.isComment">{{betaFeatures.dataList[betaFeatures.selIndx].Name}}</span>
                    </h5>
                    <button class="close" type="button" (click)="hideBetaFeaturesModal()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <table class="table" *ngIf="betaFeatures.isList">
								<thead>
									<tr>
										<th scope="col">
											<div class="text-truncate truncate">Feature Preview Name</div>
										</th>
									</tr>
								</thead>
								<tbody>
                                    <ng-container *ngFor="let fp of betaFeatures.dataList; let i = index">
										<tr>
											<td (click)="handleFeaturePrevClick(i)" role="button">
												<div class="text-truncate truncate">
													{{fp.Name}}
                                                    <span *ngIf="fp.Feature_Preview_Engagements__r" title="Interested" class="ps-2">
                                                        <i class="fa-solid fa-star"></i>
                                                    </span>
												</div>
											</td>
										</tr>
									</ng-container>
                                    <tr *ngIf="!betaFeatures.dataList || betaFeatures.dataList.length == 0">
                                        <td class="text-center">There are no beta programs available at this time</td>
                                    </tr>
								</tbody>
							</table>
                            <div class="feature-preview-details" *ngIf="betaFeatures.isDetail">
                                <div class="mb-4">
                                    <h6>Name</h6>
                                    <div [innerHTML]="betaFeatures.dataList[betaFeatures.selIndx].Name"></div>
                                </div>
                                <div class="mb-4">
                                    <h6>Requirements</h6>
                                    <div [innerHTML]="betaFeatures.dataList[betaFeatures.selIndx].Requirements__c"></div>
                                </div>
                                <div class="mb-4">
                                    <h6>Summary</h6>
                                    <div [innerHTML]="betaFeatures.dataList[betaFeatures.selIndx].Summary__c"></div>
                                </div>
                            </div>
                            <div class="feature-preview-details" *ngIf="betaFeatures.isComment">
                                <h6>Comments</h6>
                                <textarea class="form-control" placeholder="type here..." [(ngModel)]="betaFeatures.comment"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" *ngIf="betaFeatures.isList">
                    <button class="btn btn-secondary" type="button" (click)="hideBetaFeaturesModal()">Cancel</button>
                </div>
                <div class="modal-footer" *ngIf="betaFeatures.isDetail">
                    <button class="btn btn-primary" type="button" (click)="backFPDetailPage()">Back</button>
                    <button *ngIf="!betaFeatures.dataList[betaFeatures.selIndx].Feature_Preview_Engagements__r" class="btn btn-primary" type="button" (click)="signUpForFP(true)">I am interested</button>
                    <button *ngIf="betaFeatures.dataList[betaFeatures.selIndx].Feature_Preview_Engagements__r" class="btn btn-primary" type="button" (click)="signUpForFP(false)">I am no longer interested</button>
                    <button class="btn btn-secondary" type="button" (click)="hideBetaFeaturesModal()">Cancel</button>
                </div>
                <div class="modal-footer" *ngIf="betaFeatures.isComment">
                    <button class="btn btn-primary" type="button" (click)="updateInterest()">Save</button>
                    <button class="btn btn-secondary" type="button" (click)="backFPCommentPage()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>