import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CopilotComponent } from './components/copilot/copilot.component';
import { FeaturerequestsComponent } from './components/featurerequests/featurerequests.component';

const routes: Routes = [
  { path: '', component: CopilotComponent, data: { title: 'Copilot' } },
  { path: 'feature-requests', component: FeaturerequestsComponent, data: { title: 'Feature Requests', fullWidth: true } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }