import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { isDevMode } from '@angular/core';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  isFullWidthPage: any = false;
  isVerified: any = false;
  loginMessage: any = '';
  preferenceData: any;
  showPreferences: any = false;
  showEmailInput : any = false;
  showAccessCode: any = false;
  newUserData: any = {};

  constructor(titleService: Title, router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var title: any = this.getTitle(router.routerState, router.routerState.root).join('-');
        titleService.setTitle(title);
      } else if (event instanceof RoutesRecognized) {
        let route = event.state.root.firstChild;
        this.isFullWidthPage = route?.data['fullWidth'];
      }
    });
  }

  ngOnInit(): void {
    commons.loader.show();
    var self = this;
    var param:any = {};
    let currentUrl = window.location.href;
    var url = new URL(currentUrl);
    if (url.searchParams.get("callback")) {
      var callback = url.searchParams.get("callback") || '';
      url = new URL(decodeURIComponent(callback));
    }
    let originUrls: any = window.location.ancestorOrigins;
    if (originUrls) {
      originUrls = originUrls.length > 0 ? originUrls[originUrls.length - 1] : '';
    }
    param["email"] = url.searchParams.get("email");
    param['url'] = originUrls ? originUrls : ((window.location != window.parent.location) ? document.referrer : document.location.href);

    let email = commons.getUserEmail();
    if (!commons.isOpenFromAvSight() && !email) {
      commons.loader.hide();
      this.showEmailInput = true;
      return;
    }
    if (!commons.isOpenFromAvSight() && email) {
      param.email = email;
    }

    commons.jqueryAjax(environment.BASE_URL + "verify", { data: JSON.stringify(param) }, function(res:any) {
        commons.loader.hide();
        if (res.status != 1) {
          commons.notification.show({
            type: "error",
            msg: res.message,
            timeout: 3000
          });
          return;
        }
        if (res.message) {
          self.loginMessage = res.message;
          return;
        }
        if (res.data && res.data.portalEng && !res.data.portalEng.seniority) {
          res.data.contact.name = res.data.contact.name || '';
          res.data.contact.companyName = res.data.contact.companyName || '';
          res.data.contact.phone = res.data.contact.phone || '';
          self.preferenceData = res.data;
          self.preferenceData.selNotification = res.data.notificationPreferences;
          self.showPreferences = true;
          return;
        }
        self.isVerified = true;
        localStorage.setItem('reqData', JSON.stringify(res.data));
    });
  }

  verifyEmail() {
    if (!this.newUserData.email || this.newUserData.email.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter your email to continue.",
        timeout: 3000
      });
      return;
    }

    var self = this;
    commons.loader.show();
    let param: any = {
      email: this.newUserData.email
    }
    commons.jqueryAjax(environment.BASE_URL + "verify", { data: JSON.stringify(param) }, function(res:any) {
      if (res.status != 1) {
        commons.loader.hide();
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      if (res.message) {
        commons.loader.hide();
        self.loginMessage = res.message;
        self.showEmailInput = false;
        return;
      }
      if (res.data && res.data.portalEng && !res.data.portalEng.seniority) {
        commons.loader.hide();
        res.data.contact.name = res.data.contact.name || '';
        res.data.contact.companyName = res.data.contact.companyName || '';
        res.data.contact.phone = res.data.contact.phone || '';
        self.preferenceData = res.data;
        self.preferenceData.selNotification = res.data.notificationPreferences;
        self.showPreferences = true;
        self.showEmailInput = false;
        return;
      }
      self.sendCode();
    });
  }

  sendCode() {
    commons.loader.show();
    var self = this;
    let param: any = {
      email: this.newUserData.email
    }
    commons.jqueryAjax(environment.BASE_URL + "code/send", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.showPreferences = false;
      self.showEmailInput = false;
      self.newUserData.accessCode = '';
      self.showAccessCode = true;
    });
  }

  backToEmailInput() {
    this.showAccessCode = false;
    this.showEmailInput = true;
  }

  verifyCode() {
    if (!this.newUserData.accessCode || this.newUserData.accessCode.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter access code to continue.",
        timeout: 3000
      });
      return;
    }

    var self = this;
    commons.loader.show();
    let param: any = {
      email: this.newUserData.email.trim(),
      code: this.newUserData.accessCode.trim(),
    }
    commons.jqueryAjax(environment.BASE_URL + "code/verify", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.showAccessCode = false;
      self.setCookie('copilot', param.email, 1);
      self.setCookie('accesscode', param.code, 1);
      self.ngOnInit();
    });
  }

  updatePreferences() {
    let roles: any = [], notificationPref: any = [], seniority: any = '', businessSegment: any = [];
    let tpnDirectory: any = false, supportRep: any = false;
    $("[id^='role']").each(function(this: any) {
      $(this).is(":checked") ? roles.push($(this).val()) : '';
    });
    seniority = $("[id^='seniority']:checked").val();
    $("[id^='pref']").each(function(this: any) {
      $(this).is(":checked") ? notificationPref.push($(this).val()) : '';
    });
    $("[id^='seg']").each(function(this: any) {
      $(this).is(":checked") ? businessSegment.push($(this).val()) : '';
    });
    tpnDirectory = $("#tpnDirectory").is(":checked");
    supportRep = $("#supportRep").is(":checked");

    if (!this.preferenceData.contact.companyName || this.preferenceData.contact.companyName.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter your company name to continue.",
        timeout: 3000
      });
      return;
    }
    if (!this.preferenceData.contact.name || this.preferenceData.contact.name.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter your name to continue.",
        timeout: 3000
      });
      return;
    }
    if (!this.preferenceData.contact.phone || this.preferenceData.contact.phone.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter your contact # to continue.",
        timeout: 3000
      });
      return;
    }
    if (!this.preferenceData.contact.name || this.preferenceData.contact.name.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter your name to continue.",
        timeout: 3000
      });
      return;
    }
    if (!this.preferenceData.jobTitle || this.preferenceData.jobTitle.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter your job title to continue.",
        timeout: 3000
      });
      return;
    }
    if (roles.length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please select role to continue.",
        timeout: 3000
      });
      return;
    }
    if (!seniority || seniority.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please select seniority to continue.",
        timeout: 3000
      });
      return;
    }
    if (notificationPref.length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please select notification preference to continue.",
        timeout: 3000
      });
      return;
    }
    var self = this;
    commons.loader.show();
    var url = new URL(window.location.href);
    let originUrls: any = window.location.ancestorOrigins;
    if (originUrls) {
      originUrls = originUrls.length > 0 ? originUrls[originUrls.length - 1] : '';
    }
    let param: any = {
      role: roles.join(";"),
      seniority: seniority,
      notification: notificationPref.join(";"),
      businessSegment: businessSegment.join(";"),
      tpnDirectory: tpnDirectory,
      url: originUrls ? originUrls : ((window.location != window.parent.location) ? document.referrer : document.location.href),
      email: commons.isOpenFromAvSight() ? url.searchParams.get("email") : self.newUserData.email,
      name: self.preferenceData.contact.name,
      companyName: self.preferenceData.contact.companyName,
      supportRep: supportRep,
      phone: self.preferenceData.contact.phone,
      jobTitle: self.preferenceData.jobTitle
    }
    commons.jqueryAjax(environment.BASE_URL + "preferences/update", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      if (!commons.isOpenFromAvSight()) {
        self.sendCode();
        return;
      }
      self.showPreferences = false;
      self.ngOnInit();
    });
  }

  onExpandContactSupport(event: any) {
    $(".support-container .collapsible").toggleClass("d-none");
    $(".support-container .expand-icon").toggleClass("rotate");
    $(window).trigger("resize");
  }
  openKB() {
    if (isDevMode()) {
      window.open('https://inscaletech--internalsw.sandbox.my.site.com/support/s/knowledgebase', '_blank');
    } else {
      window.open('https://inscaletech.my.site.com/support/s/knowledgebase', '_blank');
    }
  }
  openPortal() {
    let currentUrl = window.location.href;
    var url = new URL(currentUrl);
    if (isDevMode()) {
      let portalUrl = 'https://avscpd.eastus.cloudapp.azure.com:8080/?email=' + (url.searchParams.get("email") || '');
      portalUrl += '&code=' + (url.searchParams.get("code") || '');
      window.open(portalUrl, '_blank');
    } else {
      window.open('https://inscaletech.my.site.com/support/s/', '_blank');
    }
  }
  openUniversity() {
    window.open('https://support.avsight.net/', '_blank');
  }

  setCookie(name: any, value: any, days: any) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state:any, parent:any): any {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
