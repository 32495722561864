import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CopilotComponent } from './components/copilot/copilot.component';
import { FeaturerequestsComponent } from './components/featurerequests/featurerequests.component';
import { ReleasenotesComponent } from './components/releasenotes/releasenotes.component';
import { UsagestatisticsComponent } from './components/usagestatistics/usagestatistics.component';

@NgModule({
  declarations: [
    AppComponent,
    CopilotComponent,
    FeaturerequestsComponent,
    ReleasenotesComponent,
    UsagestatisticsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }