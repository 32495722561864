import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-featurerequests',
  templateUrl: './featurerequests.component.html',
  styleUrl: './featurerequests.component.css'
})
export class FeaturerequestsComponent implements OnInit {
  isDataLoaded = false;
  isValidUser = false;
  filterParam: any = {
    category: '',
    sortType: 'Popular',
    seletedStatus: 'Ideas',
    showMyReq: false,
    incApproved: false
  };
  pagination: any = {
    pageSize: 10,
    totalPage: 1,
    pageNo: 1
  };
  featureReqData: any = {
    featurePointsCategoryList: [],
    featurePointsStatusList: [
      'Ideas', 'Chosen', 'Delivered', 'Rejected'
    ]
  };
  featureRequestList: any = [];
  featureReq: any = {};
  frIdParam: any = '';

  ngOnInit(): void {
    var self = this;
    this.frIdParam = commons.getUrlParameter('Id')
    commons.loader.show();
    var self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    commons.jqueryAjax(environment.BASE_URL + "verify", { data: JSON.stringify(param) }, function (res: any) {
      if (res.status != 1) {
        commons.loader.hide();
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.isDataLoaded = true;
      if (res.status == 1 && res.data && res.data.status == 'Requested') {
        commons.loader.hide();
        self.isDataLoaded = true;
        self.isValidUser = false;
        return;
      }
      self.isValidUser = true;
      self.getRequiredData(function() {
        self.getFeatureRequests();
      });
    });
  }

  getEmailFromUrl() {
    return commons.getUserEmail();
  }

  getRequiredData(callback: any) {
    var self = this;
    var param: any = {};
    param.email = this.getEmailFromUrl();
    commons.jqueryAjax(environment.BASE_URL + "featurerequest/requiredlist", { data: JSON.stringify(param) }, function (res: any) {
      if (res.status == 1) {
        self.featureReqData.existingVotes = res.data.existingVotes || [];
        self.featureReqData.featurePointsCategoryList = res.data.featurePointsCategoryList;
        self.featureReqData.remainingFeaturePoints = res.data.remainingFeaturePoints || 0;
      }
      if (callback) {
        callback();
      } else {
        commons.loader.hide();
      }
    });
  }

  onFilterChange() {
    this.filterParam.applied = true;
  }

  onApplyFilter() {
    this.frIdParam = '';
    commons.loader.show();
    this.pagination.pageNo = 1;
    this.getFeatureRequests();
  }

  clearFilter() {
    this.filterParam.searchText = '';
    this.filterParam.category = '';
    this.filterParam.sortType = 'Popular';
    this.filterParam.seletedStatus = 'Ideas';
    this.filterParam.showMyReq = false;
    this.filterParam.applied = false;
    commons.loader.show();
    this.onApplyFilter();
  }

  getFeatureRequests() {
    var self = this;
    this.filterParam.searchText = this.filterParam.searchText || '';
    var param = JSON.parse(JSON.stringify(this.filterParam));
    param.searchText = encodeURIComponent(param.searchText);
    param.sortDirection = 'DESC';
    param.email = this.getEmailFromUrl();
    param.pageSize = this.pagination.pageSize;
    param.pageNo = this.pagination.pageNo;
    commons.jqueryAjax(environment.BASE_URL + "featurerequest/getlist", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      res.data.featureList.forEach((item: any) => {
        item.isVoted = self.featureReqData.existingVotes.includes(item.Id);
      });
      self.featureRequestList = self.frIdParam ? res.data.featureList.filter((fr: any) => fr.Id == self.frIdParam) : res.data.featureList;
      if (res.data.totalPage) {
        self.pagination.totalPage = res.data.totalPage;
      }
    });
  }

  changePage(pageNo: any) {
    commons.loader.show();
    this.pagination.pageNo = pageNo;
    this.getFeatureRequests();
  }

  onFeatureItemClick(event: any) {
    var indx = +event.currentTarget.dataset.index;
    this.featureReq = JSON.parse(JSON.stringify(this.featureRequestList))[indx];
    commons.loader.show();
    this.getRequiredData(function() {
      $("#featureReqDataModal").modal("show");
    });
  }

  hideFeatureReqDialog() {
    $("#featureReqDataModal").modal("hide");
    this.featureReq = {};
  }

  voteFeatureReq(frId: any, isUndo: any) {
    var self = this;
    var param = {
      featureRequestId: frId,
      email: this.getEmailFromUrl()
    };
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "featurerequest/vote", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }

      isUndo ? self.featureReqData.remainingFeaturePoints++ : self.featureReqData.remainingFeaturePoints--;

      commons.notification.show({
        type: "success",
        msg: isUndo ? 'You removed your vote and have ' + self.featureReqData.remainingFeaturePoints + ' vote(s) left.' : 'You voted and have ' + self.featureReqData.remainingFeaturePoints + ' vote(s) left.',
        timeout: 3000
      });
      let reqIndex = self.featureRequestList.findIndex((fr: any) => fr.Id == frId);
      if (isUndo) {
        self.featureRequestList[reqIndex].Total_Points__c = (self.featureRequestList[reqIndex].Total_Points__c || 0) - 1;
        self.featureRequestList[reqIndex].isVoted = false;
        self.featureReqData.existingVotes = self.featureReqData.existingVotes.filter((id: any) => id != frId);
      } else {
        self.featureRequestList[reqIndex].Total_Points__c = (self.featureRequestList[reqIndex].Total_Points__c || 0) + 1;
        self.featureRequestList[reqIndex].isVoted = true;
        self.featureReqData.existingVotes.push(frId);
      }
      self.hideFeatureReqDialog();
    });
  }

  showFeedbackModal(indx: any) {
    this.featureReq = JSON.parse(JSON.stringify(this.featureRequestList))[indx];
    $("#frFeedback").modal("show");
  }

  hideFeedbackModal() {
    $("#frFeedback").modal("hide");
    $("#frFeedback .feedback-message").val("");
    this.featureReq = {};
  }

  submitFeedback() {
    let message = $("#frFeedback .feedback-message").val();

    if (!message || message.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter feedback message to continue.",
        timeout: 3000
      });
      return;
    }

    let self = this;
    commons.loader.show();
    let param: any = {
      email: this.getEmailFromUrl(),
      message: message,
      frId: this.featureReq.Id,
    };
    commons.jqueryAjax(environment.BASE_URL + "featurerequest/feedback", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      commons.notification.show({
        type: "success",
        msg: "Feedback submitted successfully.",
        timeout: 3000
      });
      self.hideFeedbackModal();
    });
  }

  showShareModal(indx: any) {
    this.featureReq = JSON.parse(JSON.stringify(this.featureRequestList))[indx];
    this.featureReq.url = window.location.origin + '/feature-requests?Id=' + this.featureReq.Id;
    $("#frShare").modal("show");
  }

  hideShareModal() {
    $("#frShare").modal("hide");
    $("#frShare .feedback-message").val("");
    this.featureReq = {};
  }

  copyUrl(e: any, url: any) {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(url).then(function() {
      $(e.target).addClass('text-success')
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy url: ', err);
    });
  }

  showContactModal() {
    $("#frContact").modal("show");
  }

  hideContactModal() {
    $("#frContact").modal("hide");
    $("#frContact .message").val("");
  }

  contactAdmin() {
    let message = $("#frContact .message").val();

    if (!message || message.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter message to continue.",
        timeout: 3000
      });
      return;
    }

    let self = this;
    commons.loader.show();
    let param: any = {
      email: this.getEmailFromUrl(),
      message: message
    };
    commons.jqueryAjax(environment.BASE_URL + "featurerequest/contactadmin", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      commons.notification.show({
        type: "success",
        msg: "Contact request submitted successfully.",
        timeout: 3000
      });
      self.hideContactModal();
    });
  }

}
