// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://dev-copilot.avsight.net/apis/',
  releases: [
    {name: 'Denali 1', article_url: 'https://inscaletech.my.site.com/support/s/article/d1'},
    {name: 'Denali 2', article_url: 'https://inscaletech.my.site.com/support/s/article/d2'},
    {name: 'Denali 3', article_url: 'https://inscaletech.my.site.com/support/s/article/d3'}
  ]
};
