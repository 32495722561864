import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-usagestatistics',
  templateUrl: './usagestatistics.component.html',
  styleUrl: './usagestatistics.component.css'
})
export class UsagestatisticsComponent implements OnInit {
  @Input() usageStatsList: any;

  ngOnInit(): void {
  }
}
